<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card flat>
          <v-card-title class="title">
            <!-- <v-icon>layers</v-icon>  -->

            &nbsp; Post Opening
          </v-card-title>

          <v-card flat>
            <v-card outlined>
              <v-card-title class="title" style="margin-top: 10px">
                <v-flex xs3 sm3>
                  <v-select
                    outlined
                    dense
                    label="From Fiscal Years"
                    prepend-icon="event"
                    :items="fiscalYears"
                    v-model="fromFiscalYear"
                  >
                  </v-select>
                </v-flex>
                <v-flex xs3 sm3>
                  <v-select
                    outlined
                    dense
                    label="To Fiscal Years"
                    prepend-icon="event"
                    :items="fiscalYears"
                    :disabled="!fromFiscalYear"
                    v-model="toFiscalYear"
                  >
                  </v-select>
                </v-flex>
                <v-flex xs3 sm3>
                  <v-select
                    outlined
                    dense
                    label="Select Opening Heads"
                    prepend-icon="event"
                    :items="accountHeads"
                    :disabled="!toFiscalYear"
                    v-model="accountHead"
                  >
                  </v-select>
                </v-flex>
                <v-flex xs3 sm3>
                  <add-button
                    permission="section-create"
                    @action="getLedgerHeads()"
                    icon="search"
                    v-if="accountHead"
                    >Search
                  </add-button>
                </v-flex>
              </v-card-title>
            </v-card>
          </v-card>
          <v-card>
            <div class="v-datatable v-table theme--light">
              <table
                class="reporting_acc_table"
                border="1"
                padding="0"
                style="width: 100%"
              >
                <thead>
                  <tr>
                    <th style="width: 50px" rowspan="2">SN</th>
                    <th rowspan="2" style="width: 300px">Account Heads</th>
                    <th colspan="2">Balance</th>
                  </tr>
                  <tr>
                    <th style="width: 100px">Debit</th>
                    <th style="width: 100px">Credit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>
                      <strong>{{ ++index }}</strong>
                    </td>
                    <td>
                      <strong>{{ item.name }}</strong>
                    </td>
                    <td>
                      <strong v-if="item.amount > 0">
                        {{ item.amount ? item.amount.currency() : '0' }}</strong
                      >
                    </td>
                    <td>
                      <strong v-if="item.amount < 0">
                        {{
                          item.amount ? Math.abs(item.amount).currency() : '0'
                        }}</strong
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </v-card>
          <br />
          <!-- v-if="items.length > 0 && $auth.isAdmin()" -->
          <!-- && $auth.getRole() === 'super' -->
          <v-btn
            :disabled="items.length < 1"
            style="width: 98%"
            color="success"
            @click="syncModel()"
            large
            >Sync To Ledger Opening</v-btn
          >
          <v-btn
            :disabled="items.length < 1"
            style="width: 98%"
            color="primary"
            @click="dialog = true"
            large
            >Post To Ledger Opening</v-btn
          >
        </v-card>
      </v-flex>
      <v-dialog v-model="syncModelDialog" width="400px">
        <v-card>
          <v-card-title class="primary white--text">
            <v-icon large left> info </v-icon>
            <span class="title font-weight-light"
              >Synchronize Ledger Openings</span
            >
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-sm pa-0>
              <v-layout wrap>
                <v-alert
                  v-if="syncResult === true"
                  :value="true"
                  color="success"
                  icon="priority_high"
                  outline
                >
                  Ledgers Opening are in sync
                </v-alert>
                <v-alert
                  v-if="syncResult === false"
                  :value="true"
                  color="warning"
                  icon="priority_high"
                  outline
                >
                  Ledgers Opening are not in sync
                </v-alert>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              flat
              @click="syncModelDialog = false"
              outline
            >
              close
            </v-btn>
            <!-- <v-btn color="success" flat @click="postToOpening" outline>
              Yes
            </v-btn> -->
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog" width="400px">
        <v-card>
          <v-card-title class="primary white--text">
            <v-icon large left> info </v-icon>
            <span class="title font-weight-light">Post To Ledger Openings</span>
          </v-card-title>
          <br />
          <v-card-text>
            <v-container fluid grid-list-sm pa-0>
              <v-layout wrap>
                <v-alert
                  :value="true"
                  color="warning"
                  icon="priority_high"
                  outlined
                >
                  Please Verify All The Data before Posting..!!
                </v-alert>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text @click="dialog = false" outlined>
              close
            </v-btn>
            <v-btn color="success" text @click="postToOpening" outlined>
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    fiscalYears: [],
    fromFiscalYear: '',
    toFiscalYear: '',
    accountHead: '',
    accountHeads: [
      { value: 'assets', text: 'Assets' },
      { value: 'liabilities', text: 'Liabilities' },
    ],
    items: [],
    dialog: false,
    syncModelDialog: false,
    syncResult: false,
  }),
  mounted() {
    this.fetchFiscalYear();
  },
  methods: {
    fetchFiscalYear() {
      this.$rest
        .get('/api/fiscal-year?rowsPerPage=100&descending=true')
        .then(({ data }) => {
          this.fiscalYears = data.data.map((res) => {
            return {
              value: res.id,
              text: res.name,
            };
          });
        });
    },
    getLedgerHeads() {
      this.$rest
        .get(
          `api/account-ledger-head?fromFiscalYear=${this.fromFiscalYear}&accountHead=${this.accountHead}`
        )
        .then(({ data }) => {
          this.items = data;
        });
    },
    postToOpening() {
      this.$rest
        .post(`api/account-ledger-head`, {
          items: this.items,
          fromFiscalYear: this.fromFiscalYear,
          toFiscalYear: this.toFiscalYear,
          accountHead: this.accountHead,
        })
        .then((res) => {
          this.dialog = false;
          this.$events.fire('notification', {
            message: res.data.message,
            status: 'success',
          });
        })
        .catch((err) => {
          this.dialog = false;
          this.$events.fire('notification', {
            message: err.response.message,
            status: 'error',
          });
        })
        .finally(() => {
          this.dialog = false;
        });
    },
    syncModel() {
      this.$rest
        .post('api/sync-ledger-head', {
          items: this.items,
          fromFiscalYear: this.fromFiscalYear,
          toFiscalYear: this.toFiscalYear,
          accountHead: this.accountHead,
        })
        .then((res) => {
          this.syncModelDialog = true;
          this.syncResult = true;
        })
        .catch((err) => {
          this.syncModelDialog = true;
          this.syncResult = false;
          this.$events.fire('notification', {
            message: err.response.message,
            status: 'error',
          });
        })
        .finally(() => {
          this.dialog = false;
        });
    },
  },
};
</script>

<style scoped></style>
